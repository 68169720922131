import { paginatedSchema } from '@medizzy/api';
import type { APIMethodParams } from '@medizzy/api';
import { z } from 'zod';
import { fetchLearningCategoryResponseSchema } from './fetch-learning-category.ts';

export const fetchLearningCategoriesRequestSchema = z.object({
  access: z.enum(['all', 'free', 'premium']).optional(),
  visibility: z.enum(['public', 'private']).optional(),
  user: z.number().or(z.literal('all')).optional(),
  tags: z.string().array().optional(),
  type: z.enum(['all', 'mcq', 'flashcard', 'flashcard_favourite']).optional(),
  top: z.boolean().or(z.literal('all')).optional(),
  category: z.number().optional(),
  size: z.number().optional(),
  page: z.number().optional(),
  review: z.boolean().optional(),
});

export const fetchLearningCategoriesResponseSchema = paginatedSchema(
  fetchLearningCategoryResponseSchema,
);

export type FetchLearningCategoriesParams = z.infer<
  typeof fetchLearningCategoriesRequestSchema
>;

export function fetchLearningCategories({
  client,
  params,
}: APIMethodParams<FetchLearningCategoriesParams>) {
  return client
    .url('/v2/learning/categories')
    .query(fetchLearningCategoriesRequestSchema.parse(params))
    .get()
    .valid(fetchLearningCategoriesResponseSchema);
}
